import { Mixpanel } from "mixpanel-browser";
import { createContext } from "react";

export interface ConfigVariables {
  MIXPANEL_TOKEN: string | undefined;
  Mixpanel: Mixpanel | undefined;
  CODA_ENV: string | undefined;
  HOTJAR_ID: number | undefined;
  HOTJAR_VERSION: number | undefined;
  HTTPS: boolean;
  K8: boolean;
  CODA_LP_URL: string | undefined;
  STORAGE_NAME: string | undefined;
  OAUTH_CLIENT_ID: string | undefined;
  CODA_URL: string | undefined;
}

export const initialConfigVariablesValue: ConfigVariables = {
  MIXPANEL_TOKEN: undefined,
  Mixpanel: undefined,
  CODA_ENV: undefined,
  HOTJAR_ID: undefined,
  HOTJAR_VERSION: undefined,
  HTTPS: false,
  K8: false,
  CODA_LP_URL: undefined,
  STORAGE_NAME: undefined,
  OAUTH_CLIENT_ID: undefined,
  CODA_URL: undefined,
};

export const ConfigVariablesContext = createContext<ConfigVariables>(
  initialConfigVariablesValue,
);
