import React, { CSSProperties } from "react";

import { ColorWeight, styled, Theme } from "@styles/theme";
import {
  BreakPoints,
  formatProp,
  ResponsiveProps,
  responsiveStyling,
} from "@styles/responsive";
import { getColorDotWeight } from "@styles/helpers";
import { Flex, Box } from "./ResponsiveBox";
import { Text } from "./Text";

type ColorName = keyof Theme["colors"];

export type Props = ResponsiveProps & {
  text?: string;
  size?: keyof Theme["spacing"];
  color?: ColorName;
  width?: CSSProperties["width"];
  height?: CSSProperties["height"];
  colorWeight?: ColorWeight;
  vertical?: boolean;
  style?: CSSProperties;
  display?:
    | CSSProperties["display"]
    | { [key in BreakPoints]?: CSSProperties["display"] };
};

const formatSize = (size: number | string): string =>
  typeof size === "number" ? `${size}px` : size;

const Wrapper = styled.div<Props>`
  background-color: ${(p) =>
    getColorDotWeight(`${p.color || "neutral"}.${p.colorWeight || "400"}`)};
  margin: ${(p) =>
    `${p.vertical ? 0 : ""} ${p.theme.spacing[p.size || "xxg"]} ${
      !p.vertical ? 0 : ""
    }`};
  min-width: ${(p) =>
    p.vertical ? "1px" : p.width ? formatSize(p.width) : "initial"};
  ${(p) =>
    responsiveStyling({
      display: formatProp<CSSProperties["display"]>(p.display, "block"),
      width: formatProp<string | number>(
        p.width,
        p.vertical ? "1px" : p.width ? formatSize(p.width) : "100%",
      ),
      height: formatProp<string | number>(
        p.height,
        p.vertical ? (p.height ? formatSize(p.height) : "100%") : "1px",
      ),
    })}
`;

export const Divider = ({ text, vertical = false, ...props }: Props) => {
  if (!text) {
    return <Wrapper vertical={vertical} {...props} />;
  }

  return (
    <Flex direction="row" alignItems="center">
      <Wrapper vertical={vertical} {...props} />
      <Box pX="md">
        <Text colorWeight="300">{text}</Text>
      </Box>
      <Wrapper vertical={vertical} {...props} />
    </Flex>
  );
};
