export * from "./signIn";
export * from "./signUp";

// TODO: This is duplicated from Account/Errors since our current setup
// is unable to share code betweeen from server to client because next.js
// cannot import filed form outside it's root. Mono repo should solve this
export enum Errors {
  USER_NOT_FOUND = "Could not find user",
  ACCENTURE_USER = "Accenture user's must use Accenture login button",
  USER_ALREADY_EXISTS = "User already exists",
  FAILED_TO_CREATE_USER = "Failed to create user",
  EMAIL_PASSWORD_INVALID = "Email or password is invalid",
  EMAIL_IN_USE = "There is already an user associated with this email",
  EMAIL_NOT_CORPORATE = "You must enter your business email address",
  USER_NOT_VERIFIED = "This email account is not verified",
  FAILED_TO_SEND_VERIFICATION_EMAIL = "Email failure",
  CODE_RESEND_INTERVAL = "This request exceeds the minimum interval to resend the code",
  CODE_EXPIRED = "This code already expired",
  CODE_INVALID = "The code you entered seems to be incorrect. Please try again or request a new code",
  CODE_UNEXPECTED_FAILURE = "Something went wrong",
  USER_ALREADY_VERIFIED = "User is already verified",
  GENERIC_FAILURE = "We were unable to process your request. Please try again in a few minutes.",
  INVALID_AUTH_METHOD = "Invalid authentication method",
}

export const unsafeRequestCode = (data: { email: string }) =>
  fetch("/api/account/resend-code", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
  }).then((r) => r.json());

export const unsafeVerify = (data: {
  email: string;
  code: string;
  without_validation?: boolean;
}) =>
  fetch("/api/account/verify", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
  }).then((r) => r.json());

export const unsafeVerifyTwoFactor = (data: {
  email: string;
  code: string;
  without_validation?: boolean;
}) =>
  fetch("/api/account/two-factor", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
  }).then((r) => r.json());

export const unsafeSendTwoFactor = (data: {
  email: string;
  without_validation?: boolean;
}) =>
  fetch("/api/account/two-factor/send", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
  }).then((r) => r.json());

export const unsafeResendTwoFactor = (data: {
  email: string;
  without_validation?: boolean;
}) =>
  fetch("/api/account/two-factor/resend", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
  }).then((r) => r.json());

export const unsafeSkipTwoFactor = (data: { email: string }) =>
  fetch("/api/account/two-factor/skip", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
  }).then((r) => r.json());

export const unsafeDisableTwoFactor = (data: { email: string }) =>
  fetch("/api/account/two-factor/disable", {
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    redirect: "follow",
  }).then((r) => r.json());
