import React from "react";

export interface User {
  _id: string;
  id: string;
  name: string;
  email: string;
  company: string;
  picture: string;
  onboarding: number;
  roles: string[];
  skippedInvoicesNotification?: boolean;
  skippedCodaUI?: boolean;
  updateUser?: (user: any) => Promise<any>;
  authMethod: string;
  isTrial: boolean;
  two_factor_enabled: boolean;
  skippedTwoFA: boolean;
  gotInstructionsTwoFA: boolean;
}

export const UserContext = React.createContext<User>({
  _id: "",
  id: "",
  name: "Guest",
  email: "guest@example.com",
  company: "",
  picture: "",
  onboarding: 0,
  skippedCodaUI: false,
  roles: [],
  authMethod: "",
  isTrial: false,
  two_factor_enabled: false,
  skippedTwoFA: false,
  gotInstructionsTwoFA: false,
});
