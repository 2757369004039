import React from "react";
import { useAnalytics } from "@hooks/useAnalytics";
import { useRouter } from "next/router";
import { styled, Theme } from "../../styles/theme";
import Icon from "../../icons/Icon";
import { Text } from "./Text";
import { Flex } from "./ResponsiveBox";
import Link from "./Link";

export interface Props {
  href?: string;
  as?: string;
  onPress?: () => void;
  noPadding?: boolean;
  color?: keyof Theme["colors"];
  colorWeight?: string;
  fill?: string;
}

const Container = styled(Flex)`
  svg {
    display: block;
    min-width: 16px;
    margin-right: ${(p) => p.theme.spacing.xxs};
  }
`;

const Button = styled.button<{ noPadding?: boolean }>`
  background: none;
  border: none;
  outline: none;
  padding: ${(p) => (p.noPadding ? p.theme.spacing.none : p.theme.spacing.xxs)};
  :focus {
    box-shadow: ${(p) => p.theme.shadow.focus};
  }
`;

export const BackButton: React.FC<Props> = ({
  href,
  as,
  onPress,
  children,
  noPadding,
  color,
  colorWeight,
  fill = "primary.300",
}) => {
  const router = useRouter();
  const { track } = useAnalytics();
  const Btn = (
    <Container direction="row" alignItems="center" cursor="pointer">
      <Icon fill={fill} name="arrow-circle-left" width={16} height={16} />
      <Text
        size={12}
        transform="uppercase"
        color={color || "primary"}
        colorWeight={colorWeight || "300"}
        weight={400}
      >
        {children}
      </Text>
    </Container>
  );

  return onPress ? (
    <Button onClick={onPress} noPadding={noPadding}>
      {Btn}
    </Button>
  ) : (
    <Link
      href={href || "#"}
      as={as}
      onClick={() => {
        if (href !== undefined) {
          track("BACK_BUTTON", {
            PREVIOUS_URL: router.pathname,
            NAVIGATED_URL: href,
          });
        }
      }}
    >
      {Btn}
    </Link>
  );
};
