import qs from "query-string";
import { useState, useEffect } from "react";

import { Errors } from "@services/index";

export const ErrorCodes = {
  // "0": [Errors.USER_NOT_FOUND],
  "0": "There is no user associated with this email.",
  // "1": [Errors.LOCAL_EMAIL_IN_USE],
  "1": "This email is already in use. You can reset your password if needed.",
  // "2": [Errors.FAILED_TO_CREATE_USER],
  "2": "Something went wrong. Please try again later.",
  // [Errors.GENERIC_FAILURE]: 3,
  "3": "Something went wrong. Please try again later.",
  // "4": [Errors.THIRD_PARTY_EMAIL_IN_USE],
  "4": "This email is already associated with a third party authentication account.",
};

/**
 *  Check if there is an `e` param in the url then erases it and
 *  return an error associeated with that code
 */
export const useEfemeralUrlError = (deps = []) => {
  const [urlError, setUrlError] = useState<string | null>(null);

  useEffect(() => {
    // Avoid SSR
    if (!window) return;

    const params = qs.parse(window.location.search);
    const error = Array.isArray(params?.e) ? params.e[0] : params.e || false;

    if (error && error in ErrorCodes) {
      delete params.e;
      const code = error as keyof typeof ErrorCodes;
      const newPath = `${location.pathname}?${qs.stringify(params)}`;

      window.history.replaceState({}, document.title, newPath);
      setUrlError(ErrorCodes[code] || Errors.GENERIC_FAILURE);
    }
  }, [...deps]);

  return urlError;
};
