import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/pipeable";
import { toError, left, right } from "fp-ts/lib/Either";

import { Errors } from "./index";

interface Data {
  name: string;
  email: string;
  company: string;
  password: string;
  teamToken?: string;
  teamId?: string;
  source?: string;
  timeZone: string;
  plan?: string;
}

const unsafeSignUp = (body: Data) =>
  fetch("/signup/local", {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify(body),
    headers: { "Content-Type": "application/json" },
  });

/**
 *  Errors might be a map of fields -> error OR an error array
 */
const formatError = (errors: unknown) =>
  Array.isArray(errors) ? { formErrors: [Errors.GENERIC_FAILURE] } : errors;

// TODO: Retries
export const signUp = (form: Data) =>
  pipe(
    TE.tryCatch(() => unsafeSignUp(form).then((r) => r.json()), toError),
    TE.mapLeft(() => ({ formErrors: [Errors.GENERIC_FAILURE] })), // TODO: better error message
    TE.chain(({ user, errors }) =>
      TE.fromEither(user ? right(true) : left(formatError(errors))),
    ),
  );
