import qs from "query-string";
import * as TE from "fp-ts/lib/TaskEither";
import { pipe } from "fp-ts/lib/pipeable";
import { left } from "fp-ts/lib/Either";
import { Errors } from "./index";

interface Data {
  email: string;
  password: string;
  timeZone: string;
  source?: string;
  plan?: string;
}

const unsafeSignIn = ({ email, password, timeZone, source, plan }: Data) =>
  fetch(`/signin/local?${qs.stringify({ timeZone, source, plan })}`, {
    method: "POST",
    redirect: "follow",
    body: JSON.stringify({ email, password }),
    headers: { "Content-Type": "application/json" },
  });

const formatErrors = (e: unknown) => {
  const email_pass_msg =
    "Your email and password combination is incorrect. Please try again.";
  const default_msg = "Sorry, something went wrong. Please try again.";

  switch (e) {
    case Errors.CODE_RESEND_INTERVAL:
      return { formErrors: [Errors.USER_NOT_VERIFIED] };

    case Errors.USER_NOT_VERIFIED:
      return { formErrors: [Errors.USER_NOT_VERIFIED] };

    case Errors.EMAIL_PASSWORD_INVALID:
      return { formErrors: [email_pass_msg] };

    case Errors.INVALID_AUTH_METHOD:
      return { formErrors: [Errors.INVALID_AUTH_METHOD] };

    case Errors.USER_NOT_FOUND:
      return { formErrors: [Errors.USER_NOT_FOUND] };

    case Errors.ACCENTURE_USER:
      return { formErrors: [Errors.ACCENTURE_USER] };

    default:
      return { formErrors: [default_msg] };
  }
};

export const signIn = (data: Data) =>
  pipe(
    TE.tryCatch(
      () => unsafeSignIn(data),
      () => {
        return {
          formErrors: [
            "We couldn't complete your request. There might be something wrong with your connection.",
          ],
        };
      },
    ),
    TE.chain((response) => {
      if (response.status !== 200) {
        return () => response.text().then((msg) => left(formatErrors(msg)));
      }

      let url = "/instances";

      try {
        const parsed = new URL(response.url);
        const path = parsed.pathname;
        const query = parsed.search;

        if (path.indexOf("/signin") == -1) {
          url = `${path}${query}`;
        }
      } catch (error) {}

      return TE.right(url);
    }),
  );
